@use "../../styles/mixins" as *;

.pack {
    text-align: center;
    color: $green;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;

  &__sub-heading {
    font-size: 1rem;
    font-weight: 500;
  }

  &__heading {

  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include tablet {
      flex-direction: row;
      gap: .5rem;
    }

    @include desktop {
      gap: 2rem;
    }

    &--card {
      margin: 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include tablet {
        width: 33.33%;
        height: 100%;
        margin: 0;
        height: auto;
      }
    }

    &--mins {
        background-color: $blue;
        color: #fff;
        font-weight: 500;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 2rem;

        @include tablet {
          height: 2rem;
        }
    }

    &--container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        color: #000;
        padding: 1.2rem;
        flex-grow: 1;

        @include tablet {
          height: auto;
        }
    }

    &--price {
        color: $blue;
    }

    &--copy {
        padding: 1rem 0;
        border-bottom: 1px solid $blue;
        flex-grow: 1;
        
    }

    &--book {
        background-color: $green;
        color: #fff;
        padding: 1rem;
        border: 0;
        margin-top: 1rem;
        border-radius: 4px;
        align-self:auto;
        
    }
  }
}
