@use "../../styles/mixins" as *;

.about {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__heading {
        text-align: center;
        color: $green;
    }
}

b, a {
    color: $green;
    text-decoration: none;
}