@use "../../styles/mixins" as *;

.book {
    padding: 1rem;
    background-color: $green;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 360px;
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    position: fixed;
    right: 4%;
    bottom: 5.5%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;    
    text-decoration: none;
    &:hover {
        border: 1px solid #fff;
        background: darken($color: $green, $amount: 10%)
    }
    
    @include tablet {
        padding: 1rem;

    }
    
    

    &__icon {
        background-image: url("../../assets/icons/booking.png");
        width: 1.2rem;
        height: 1.2rem;
        background-repeat: no-repeat;
        background-size: cover;
    }
}