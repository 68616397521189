$tablet-breakpoint: 768px;
$desktop-breakpoint: 1279px;
$green: #739072;
$pink: #F5DAD2;
$blue: #435560;


 



@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}