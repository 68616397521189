@use "../../styles/mixins" as *;

.contact {
    // height: 100vh;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;

    @include tablet {
        flex-direction: row;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;

        @include tablet {
            padding: 0 1rem 0 0;
        }
        
        &--heading,
        &--sub-heading {
            color: $green;

        }

        &--container {
            display: flex;
            flex-direction: column;
            gap: .2rem;
        }

        &--copy {
            display: flex;
            align-items: center;
        }
    }

    &__img {
        width: 100%;
        border-radius: 15px;

        @include tablet {
            width: 40%;
        }

        @include desktop {
            height: 30%;
        }
    }
}

.icon {
    width: 1.2rem;
    height: 1.2rem;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: .5rem;
}

.email {
    background-image: url("../../assets/icons/mail.png");
}

.phone {
    background-image: url("../../assets//icons/phone.png");
}

.clock {
    background-image: url("../../assets/icons/clock.png");
}