@use "../../styles/mixins" as *;

.home {
    color: $green;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    line-height: 1.5;
    justify-content: space-evenly;

    @include tablet {
        flex-direction: row;
        text-align: left;
        gap: 2rem;
    }

    &__copy{
    
        @include tablet {
            width: 60%;
        }

    &--sub-heading {
        font-size: 1rem;
        font-weight: 500;
        word-spacing: 3px;
        letter-spacing: 4px;
        text-align: center;
        padding: .5rem 0;

        @include tablet {
            text-align: left;
        }
    }

    &--heading {
        font-size: 1.6rem;
        text-align: center;

        @include tablet {
            text-align: left;
        }
    }

    &--copy {
        color: #000;
        padding: .5rem;
        font-size: 1rem;

        @include tablet {
            padding: .7rem 0;
        }
    }

    &--link {
        color: $green;
        font-weight: 700;
        text-decoration: none;
    }
    
    }



    &__images {
        height: 30rem;
        border-radius: 15px;
        width: auto; 
        object-fit: contain;

        @include tablet {
            width: 40%;
            height: auto;
        }
    }
}