@use "../../styles/mixins" as *;

.mobile {
    padding: 1rem 0;
    color: $green;

    &__heading {
        text-align: center;
        padding-top: 1rem;
    }

    &__cards {
        padding: 1rem 0;

        @include tablet {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
    
        }
    }

    &__card {
        border: 1px solid $green;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: .6rem;

        @include tablet {
          flex: 1 0 50%;
          box-sizing: border-box;
          padding: 2rem;
          gap: 1rem;
        }
        
        &--icon {
            width: 2rem; 
            height: 2rem;
            
        }

        &--heading {

        }

        &--copy {
            color: #000;
        }

        &--left {
            // border-top-left-radius: 15px;
        }

    }


}